import React from 'react'
import { useNavigate } from 'react-router-dom'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const PrivacyPolicyFooter = () => {

  // var trust = "sss";
  var trust = "narande";

  var emailId;
  var address;
  var mobileNo;

  if (trust === "narande") {
    emailId = "narandeschool@gmail.com";
    address = "Narande HighScool Narande, Taluka- Hatkanagale, District-Kolhapur, Maharashtra.";
    mobileNo = "8329992496";
  } else if (trust === "sss") {
    emailId = "sangli.shikshan@gmail.com";
    address = "City High School Rd, Gaon Bhag, Sangli, Maharashtra 416416";
    mobileNo = "02332332299";
  }else if (trust === "jamsande") {
    emailId = "";
    address = "";
    mobileNo = "";
  }else if (trust === "devgad") {
    emailId = "devgadeducationboard@gmail.com";
    address = "";
    mobileNo = "";
  }

  const navigate = useNavigate();

  const ShowPrivacyPolicy = () => {
    navigate('/privacy-policy')
  }



  return (
    <>
      <div className="col-sm-12 p-0 m-0">
        <div className="row m-0 p-0 py-2  " style={{ backgroundColor: "#157FF6", boxShadow: "2px 2px 5px black" }}>

          <div className="col-12 col-md-1"></div>
          <div className="row col-12 col-md-10 pt-2 m-0" style={{ fontSize: "14px", justifyContent: "center", color: "white" }}>
            <div className="col-12 col-md-6" style={{ display: "flex", flexDirection: "column" }}>
              <span><RadioButtonCheckedIcon style={{ fontSize: "14px" }} /> <b>Email :</b> {emailId}</span>
              <span><RadioButtonCheckedIcon style={{ fontSize: "14px" }} />  <b>Contact :</b> {mobileNo}</span>
              <span><RadioButtonCheckedIcon style={{ fontSize: "14px" }} />  <b>Address :</b> {address}</span>
            </div>

            <div className="col-12 col-md-6 text-start" style={{ display: "flex", flexDirection: "column" }}>
              <span onClick={ShowPrivacyPolicy} style={{ cursor: 'pointer' }} ><RadioButtonCheckedIcon style={{ fontSize: "14px" }} />  <u>Privacy Policy</u></span>
              <span style={{ cursor: 'pointer' }} ><RadioButtonCheckedIcon style={{ fontSize: "14px" }} />  <u>Terms & Conditions</u></span>
              <span style={{ cursor: 'pointer' }} ><RadioButtonCheckedIcon style={{ fontSize: "14px" }} />  <u>Cancel Your Account</u></span>
            </div>
          </div>

          <div className="col-12 col-md-1"></div>
        </div>

      </div>

    </>
  )
}

export default PrivacyPolicyFooter