// export const BaseIP = "http://192.168.29.102:8081";

// export const BaseIP = "http://157.66.191.17:8080";


// Govande Sir IP
//export const BaseIP = "http://localhost:8080";

// export const BaseIP = "http://103.104.74.225:9080";

// export const BaseIP = "http://192.168.195.101:8080";

// 103.104.74.205:8080/institute_jpatest

// पलूस शिक्षण मंडळ --- मुस्लिम एज्युकेशन --- तुरची --- एमटीईएस --- भिलवडी --- पंडित --- नरंदे----शिरढोण----संख
//export const BaseIP = "http://103.104.74.205:8080";
// export const BaseIP = "https://103.104.74.205:8443";
export const BaseIP = "https://server.purnaacademic.in:8443";


//SSS WEB Build Online
//  export const BaseIP = "http://103.104.74.225:8080";
// export const BaseIP = "http://157.66.191.17:8080";

// LATTE WEB Build Online
// export const BaseIP = "http://103.104.74.221:8080";
// export const BaseIP = "http://157.66.191.18:8080";

// jamsande----shirgaon----devgad----padel
// export const BaseIP = "https://server1.purnaacademic.in:8443";
// export const BaseIP = "http://server1.purnaacademic.in:8080";


//Local Machine
// export const BaseIP="http://192.168.29.150:8080";


//https local build
//export const BaseIP = "https://192.168.29.111:7443";


//For Web Build
//export const BaseIP = "http://103.228.114.175:8080";


//For Demo School Web Build
//old export const BaseIP = "http://103.228.114.175:9080";157.66.191.17:9080
// export const BaseIP = "http://157.66.191.17:9080";


//For Demo School Local Build
//export const BaseIP = "http://192.168.29.111:9080";


//https Web build
// export const BaseIP = "https://103.104.74.205:8443";


//For Local Build
// export const BaseIP="http://localhost:8080";


//192.168.1.7
//export const BaseIP = "http://192.168.1.7:8080";


// MasterNames
// export const InstMasterName = "/institute-sss"; //SSS Sangli
// export const InstMasterName = "/institute-lkjcp"; //ACS Palus College
// export const InstMasterName = "/institute-les"; //Latthe Edu Soc
// export const InstMasterName = "/institute-anglo"; //anglo Sangli
// export const InstMasterName = "/institute-turchi"; //Turchi
// export const InstMasterName = "/institute-bsb"; //bhilwadi
// export const InstMasterName = "/institute-mtes"; //mte Sangli
// export const InstMasterName = "/institute-pvdpssp"; //Pandit Vishnu Paluskar 
export const InstMasterName = "/institute-narnde"; // narnde
// export const InstMasterName = "/institute-jamsande"; // jamsande
// export const InstMasterName = "/institute-shirgoan"; // shirgaon
// export const InstMasterName = "/institute-devgad"; // devgad
// export const InstMasterName = "/institute-shirdhon"; // shirdhon
// export const InstMasterName = "/institute-padel"; // padel
// export const InstMasterName = "/institute-sankh"; // sankh


export const BaseURL = BaseIP + InstMasterName + "/institute/sss/";
export const BaseURLMaster = BaseIP + InstMasterName + "/institute/sss/master/";

export const BaseURLAcMaster = BaseIP + InstMasterName + "/institute/sss/acmaster/";

//Export Library master path
export const BaseUrlLibraryMaster = BaseIP + InstMasterName + "/institute/sss/library/master/"

//Export folder path
export const getExportedReportAPIURL = BaseIP + "/output/";

//daud sir IP
export const dBaseIP = "http://192.168.29.102:8080";
export const dBaseURL = dBaseIP + "/institute/sss/";
export const dBaseURLMaster = dBaseIP + "/institute/sss/master/";
export const dBaseUrlLibraryMaster = dBaseIP + "/institute/sss/library/master/";
export const dBaseURLAcMaster = dBaseIP + "/institute/sss/acmaster/";